@import '~theme/variables';

.form {
  margin-top: $space-m;
  position: relative;
}

.input {
  border-radius: 9999px;
  background: $color-neutral-06;
  border: 1px solid $color-neutral-04;
  padding: $space-s;
  padding-left: $space-m;
  padding-right: 2rem;
  text-align: left;
  width: 100%;
  font-size: $body-font-size;
  font-family: $font-family-primary;
}

.buttonContainer {
  position: absolute;
  right: 0;
  top: 0;
}

.button,
.button:hover {
  @include breakpoint(sm, max) {
    background: transparent;

    & > svg {
      fill: $color-neutral-01;
    }
  }
}
