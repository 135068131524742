@import '~theme/variables';

.container {
  position: relative;
  background-color: $color-neutral-01;
  z-index: $z-index-normal;
  mask-image: url('../../../../../public/static/images/shared/header-stripes-black-inverted.svg'),
    linear-gradient(#000 0 0); /* we need this extra layer for mask-composite */
  mask-composite: exclude;
  mask-position: bottom;
  mask-repeat: no-repeat;
  mask-composite: exclude;
  padding-bottom: $space-xl;

  @include breakpoint(sm) {
    mask-image: none;
    --video-height: 67vh;

    &:before {
      content: '';
      position: absolute;
      bottom: -22px;
      left: 0;
      right: 0;
      height: 22px; // original height of the image
      background-image: url('../../../../../public/static/images/shared/header-stripes-black.svg');
      background-size: cover;
      background-repeat: no-repeat;
      z-index: $z-index-header;
    }
  }

  &.lightTheme {
    background-color: $color-neutral-06;
    &:before {
      display: none;
    }
  }
}

.video {
  z-index: $z-index-background;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  object-fit: cover;

  @include breakpoint(sm) {
    height: var(--video-height);
    border-bottom-left-radius: 48px;
    border-bottom-right-radius: 48px;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100svh;
  max-height: 82rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 3.86%,
    rgba(0, 0, 0, 0) 94.2%
  );

  @include breakpoint(sm, max) {
    &.overlapping {
      height: calc(100svh + 5.8rem);
    }
  }

  @include breakpoint(sm) {
    height: auto;
    max-height: none;
  }
}

.titleWrapper {
  width: 100%;
  padding: 0 $space-s;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: $z-index-above;

  @include breakpoint(sm) {
    flex: none;
    height: var(--video-height);
  }
}

.title {
  font-family: $font-family-display;
  font-size: 5.6rem;
  line-height: 4.8rem;
  letter-spacing: -0.015em;
  color: $color-neutral-06;

  @include breakpoint(sm) {
    font-size: 7.9rem;
    line-height: 6.7rem;
  }
}

.mobileTitle {
  @include breakpoint(sm) {
    display: none;
  }
}

.desktopTitle {
  @include breakpoint(sm, max) {
    display: none;
  }
}

.subtitle {
  font-size: $h3-font-size;
  letter-spacing: -0.015em;
  line-height: 2rem;
  color: $color-neutral-06;
  margin-top: $space-s;

  @include breakpoint(sm) {
    line-height: 2.9rem;
    font-size: 2.2rem;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 $space-s;
  gap: $space-s;
  width: 100%;

  @include breakpoint(sm) {
    flex-direction: row;
    gap: $space-s;
  }
}

.button {
  @include breakpoint(sm, max) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.primaryButton {
  @include breakpoint(sm) {
    &:hover {
      border: 2px solid $color-neutral-06;
    }
  }
}

.secondaryButton {
  .darkTheme {
    border-color: $color-neutral-06;
    color: $color-neutral-06;

    &:hover {
      border-color: $color-neutral-06;
      background: $color-neutral-06;
      color: $color-neutral-01;
    }
  }

  @include breakpoint(sm, max) {
    background-color: $color-neutral-01;
    color: $color-neutral-06;
    border: none;
    padding-top: 1.9rem;
    padding-bottom: 1.9rem;

    &:hover {
      background: $color-neutral-06;
      color: $color-neutral-01;
    }
  }
}

.mobileStickerButton {
  display: none;
  @include breakpoint(sm, max) {
    display: block;
    margin-top: $space-l;
    width: 8rem;
  }
}

.inputWrapper {
  width: 100%;
  padding: 0 $space-s;

  @include breakpoint(sm) {
    margin-left: auto;
    margin-right: auto;
    max-width: 72rem;
    z-index: $z-index-above;
  }
}

.ctaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $space-s;

  @include breakpoint(sm) {
    margin-top: -3.8rem;
  }
}
