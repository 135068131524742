@import '~theme/variables';

.dialogContent {
  --max-height: 100svh;
  max-width: 100vw;
  height: 100%;
  width: 100%;
  border-radius: 0;

  & > div {
    padding: $space-s;
  }

  h2 {
    font-size: $h2-font-size;
  }
}

.ul {
  margin-top: $space-s;

  & li {
    padding: $space-s $space-xs;
    border-bottom: 1px solid #e6e6e6;
  }
}

.suggestionLink {
  font-size: $large-font-size;
  font-weight: 500;
  width: 100%;
}

.locationButton {
  & span {
    text-decoration: underline;
    font-weight: normal;
  }
}
